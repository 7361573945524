import * as React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import useFormattedMsg from "../hooks/useFormattedMsg";
import { FormattedMessage } from "react-intl";
import { Row, Col, Container } from "react-bootstrap";
import image from "../images/computer_code.jpg";
import {
  faGaugeHigh,
  faBuildingShield,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function IndexPage() {
  const title = useFormattedMsg("our_services.title");
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>

      <Layout>
        <Container className="py-5">
          <Row className="pb-3">
            <Col md={4}>
              <div
                style={{
                  height: "500px",
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              ></div>
            </Col>
            <Col md={8}>
              <h1 className="pb-2">
                <FormattedMessage id="our_services.sd_heading" />
              </h1>
              <FormattedMessage id="our_services.sd_general" />
              <br />
              {/* Beispiel - Beschreibung von Vorteilen */}
              <Row className="py-3">
                <Col lg={6}>
                  <FontAwesomeIcon size="3x" icon={faBuildingShield} /> <br />
                  <br />
                  <FormattedMessage id="our_services.sd_heading_testing" />{" "}
                  <br />
                  <FormattedMessage id="our_services.sd_descr_testing" />
                </Col>
                <Col lg={6}>
                  <FontAwesomeIcon size="3x" icon={faGaugeHigh} /> <br /> <br />
                  <FormattedMessage id="our_services.sd_heading_performance" />{" "}
                  <br />
                  <FormattedMessage id="our_services.sd_descr_performance" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <hr />
        </Container>

        <Container className="py-5">
          <Row className="pb-3">
            <Col md={12}>
              <h1 className="pb-2">
                <FormattedMessage id="our_services.cicd_title" />
              </h1>
              <Row>
                <Col lg={6}>
                  <FormattedMessage id="our_services.cicd_descr" />
                </Col>
                <Col lg={6}>
                  <FormattedMessage id="our_services.cicd_steps" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <hr />
        </Container>

        <Container className="py-5">
          <Row className="pb-3">
            <Col md={12}>
              <h1 className="pb-2">
                <FormattedMessage id="our_services.db_title" />
              </h1>
              <Row>
                <Col lg={6}>
                  <FormattedMessage id="our_services.db_descr" />
                </Col>
                <Col lg={6}>
                  <FormattedMessage id="our_services.db_steps" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <hr />
        </Container>

        <Container className="py-5">
          <Row className="pb-3">
            <Col md={12}>
              <h1 className="pb-2">
                <FormattedMessage id="our_services.gis_title" />
              </h1>
              <Row>
                <Col lg={6}>
                  <FormattedMessage id="our_services.gis_descr" />
                </Col>
                <Col lg={6}>
                  <FormattedMessage id="our_services.gis_steps" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <hr />
        </Container>
        <Container className="py-5">
          <Row className="pb-5">
            <Col lg={6}>
              <h1 className="pb-2">
                <FormattedMessage id="our_services.sa_title" />
              </h1>
              <FormattedMessage id="our_services.sa_descr" />
            </Col>
            <Col md={6}>
              <div
                style={{
                  height: "300px",
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              ></div>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <h2>Fact 1</h2>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </Col>
            <Col lg={3}>
              <h2>Fact 2</h2>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </Col>
            <Col lg={3}>
              <h2>Fact 3</h2>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </Col>
            <Col lg={3}>
              <h2>Fact 4</h2>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
